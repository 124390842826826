<template>
  <div className="context_whole" style="position: fixed;width: 100%;height: 100%">
    <van-nav-bar
        style="background-color:rgb(48, 65, 86);height: 6%;"
        title="图片浏览"
        fixed="true"
    />
    <div style="width:100%;height: 81%;margin-top: 13%">
<!--      <div style="height:100%;width: 100%;margin: 15% 0 0 2%; ">-->
      <div style="width:500px;padding: 0 0">
        <van-grid clickable :column-num="3" :gutter="10" >
          <van-grid-item v-for="(item,index) in list" :key="index" :url="item.url" style="width:50%;margin-left: 0px;margin-top:5px;" >
            <van-image :src="item.url" style="width: 160px;"/>
            <span>{{item.name}}</span>
          </van-grid-item>
        </van-grid>
    </div>



        <!--        参数	说明	类型	默认值	版本-->
        <!--        v-model	当前页码	number	-	- -->
        <!--        mode	显示模式，可选值为 simple multi	string	multi	- -->
        <!--        total-items	总记录数	number	0	- -->
        <!--        items-per-page	每页记录数	number	10	- -->
        <!--        page-count	总页数	number	根据页数计算	- -->
        <!--        prev-text	上一页	string	上一页	- -->
        <!--        next-text	下一页	string	下一页	- -->
        <!--        show-page-size	显示的页码个数	number	5	- -->
        <!--        force-ellipse-->
      
    </div>
    <div style="width: 100%;height:14%;margin-right:5px;">
      <van-pagination
          v-model="currentPage"
          :page-count="totalPage"
          items-per-page="10"
          mode="simple"
          @change="changePage"
      />
    </div>
    <van-tabbar v-model="active" className="bottom" inactive-color="black" active-color="#1988f9" style="height: 8%">
      <van-tabbar-item name="meeting" icon="wap-home-o" to="/meeting">视频会议</van-tabbar-item>
      <!--<van-tabbar-item name="video" icon="video" to="/video">视频回放</van-tabbar-item>-->
      <!--<van-tabbar-item name="photo" icon="photo" to="/photo">图片浏览</van-tabbar-item>-->
      <van-tabbar-item name="user" icon="user-o" to="/user">个人中心</van-tabbar-item>
    </van-tabbar>
  </div>

</template>

<script>


export default {
  name: "photo",
  components: {

  },
  data() {
    return {
      active: 'photo',
      currentPage: 1,
      pageSize:8,
      totalPage:5,
      list:[
      ]
    }
  },
  async created() {
    // let url = "http://localhost:9090/file/page/page?pageNum=1&pageSize=5&type=jpg&name=1"
    // http://localhost:9090/file/page/picture/all?pageNum=1&pageSize=6
    // const res = await this.$axios.get(`http://localhost:9090/user/${this.id}`)
    // GET/user/{id};
    let acc0 = this.$route.query.AccountName || this.$route.query.accountname;//获取当前页面路径
    const base_url = 'https://root.xuxiao888.com'
    //const base_url = 'http://localhost:9090'
    const res = await this.$axios.get(`${base_url}/file/page/picture/all?pageNum=${this.currentPage}&pageSize=${this.pageSize}`)
    // console.log(res.data.code);
    // console.log(res.data);
    // console.log(res.data.data.records);
    if (res.data.code == '200') {
      let data=res.data.data;
      this.list=data.records;
      this.totalPage=data.pages;
      // console.log(data);
    // console.log(res.data.data);

    }
  },
  methods:{
      async changePage(){
    // this.currentPage=currentPage;
    console.log(this.currentPage);
    console.log("11");
    const base_url = 'https://root.xuxiao888.com'
    //const base_url = 'http://localhost:9090'
      const res = await this.$axios.get(`${base_url}/file/page/picture/all?pageNum=${this.currentPage}&pageSize=${this.pageSize}`)
      if (res.data.code == '200') {
        let data=res.data.data;
        this.list=data.records;
        // console.log(data);
        // console.log(res.data.data);

      }
    }
  },
}
</script>

<style scoped>

</style>